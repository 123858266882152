import React from 'react';
import styled from 'styled-components';

import { Icons } from 'common/Icons';

export const Box = React.forwardRef(
    ({ className, children, ...props }, ref) => (
        <BoxStyled ref={ref} className={className} {...props}>
            {children}
        </BoxStyled>
    )
);

export const Row = React.forwardRef(
    ({ className, children, ...props }, ref) => (
        <RowStyled ref={ref} className={className} {...props}>
            {children}
        </RowStyled>
    )
);

export const MessageBox = ({
    className,
    children,
    warning = false,
    success = false,
    ...props
}) => (
    <MessageBoxStyled
        className={className}
        warning={warning}
        success={success}
        {...props}
    >
        <i
            className={
                Icons[warning ? 'info' : success ? 'success' : 'warning']
            }
        />
        {children}
    </MessageBoxStyled>
);

const BoxStyled = styled.div`
    display: flex;
    flex-direction: column;
`;
const RowStyled = styled.div`
    display: flex;
`;
const MessageBoxStyled = styled.div`
    font-size: 1.4rem;
    max-width: 80rem;
    background: ${(props) =>
        props.warning
            ? props.theme.colors.warningLight
            : props.success
            ? props.theme.colors.successLight
            : props.theme.colors.errorLight};
    border: 1px solid
        ${(props) =>
            props.warning
                ? `${props.theme.colors.warning}55`
                : props.success
                ? `${props.theme.colors.success}55`
                : `${props.theme.colors.error}55`};
    color: ${(props) =>
        props.warning
            ? props.theme.colors.warningText
            : props.success
            ? props.theme.colors.successText
            : props.theme.colors.errorText};
    padding: 1rem;
    align-items: center;
    justify-content: center;
    margin: 0.7rem;
    & i {
        font-size: 1.2rem;
        margin-right: 0.7rem;
    }
    & i:not(:first-of-type) {
        margin: 0 0.7rem;
    }
`;
