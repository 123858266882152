import React from 'react';
import { Routes, Route } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { isWeekFrom, createSurnameFellowsArray } from 'common/utils';
import { Box, MessageBox } from 'components/BasicComponents';
import Button from 'components/UI/Button';

import { ScheduleRow } from './ScheduleRow';
import { ScheduleColumn } from './ScheduleColumn';

const ScheduleDataColumn = ({
    data,
    isPrinting,
    editData,
    isEditable,
    changeHandler,
    blueButton,
    redButton,
    options,
    publisherID,
    publishers,
}) => {
    const { t } = useTranslation();

    const isActual =
        !isPrinting && isWeekFrom(data.rows.find((r) => r.id === '001')?.value);

    const surnameFellows = createSurnameFellowsArray(publishers);

    const renderedRowList = [];
    const IDs = [];
    const orderedRows = [];

    editData.forEach((row, index) => {
        const wantedRow = data.rows.find((r) => r.id === row.id);

        orderedRows.push({
            ...row,
            publisherID: wantedRow?.publisherID,
            value: wantedRow?.value,
            options: options[row?.optionsCategory],
            order: index,
        });
    });

    orderedRows.forEach((row, index) => {
        if (row?.publisherID) {
            IDs.push(row.publisherID);
        }
        const isMyTask =
            !isPrinting &&
            row?.publisherID === publisherID &&
            publisherID?.length &&
            row?.publisherID?.length;

        renderedRowList.push(
            <ScheduleRow
                text={row?.value}
                data={row}
                key={index}
                isActual={isActual}
                isEditable={isEditable}
                isMyTask={isMyTask}
                changeHandler={changeHandler}
                IDs={IDs}
                surnameFellows={surnameFellows}
            />
        );
    });

    return (
        <ScheduleColumn isActual={isActual}>
            {!!renderedRowList.length ? (
                renderedRowList
            ) : (
                <MessageBoxStyled warning>
                    {t(
                        'Žiadne ID uložených riadkov v tomto stĺpci nezodpovedá nastaveným ID riadkov v nastaveniach rozpisu alebo ešte žiadne riadky nie sú vytvorené.'
                    )}
                </MessageBoxStyled>
            )}
            {!isPrinting && (
                <Routes>
                    <Route
                        path="edit"
                        element={
                            <ButtonWrapper>
                                {!!renderedRowList.length && (
                                    <Button
                                        text={
                                            isEditable
                                                ? t('Uložiť')
                                                : t('Upraviť')
                                        }
                                        type="blueFull"
                                        onClick={blueButton}
                                        icon={isEditable ? 'save' : 'edit'}
                                        my="5px"
                                    />
                                )}
                                <Button
                                    text={
                                        isEditable ? t('Zrušiť') : t('Vymazať')
                                    }
                                    type="redFull"
                                    onClick={redButton}
                                    icon={isEditable ? 'cancel' : 'delete'}
                                    my="5px"
                                />
                            </ButtonWrapper>
                        }
                    />
                </Routes>
            )}
        </ScheduleColumn>
    );
};

export default ScheduleDataColumn;

const ButtonWrapper = styled(Box)`
    align-items: center;
`;
const MessageBoxStyled = styled(MessageBox)`
    max-width: 150px;
`;
