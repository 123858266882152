import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';

import { mediaQueries } from 'common/theme';
import { Icons } from 'common/Icons';

const resetScroll = () => {
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
};

const NavLinkC = ({ icon, to, label, className = null }) => (
    <NavLinkStyled
        onClick={resetScroll}
        to={to}
        end
        className={(data) =>
            [className, data.isActive ? 'active' : ''].join(' ')
        }
        aria-hidden="true"
    >
        <i className={Icons[icon]}></i>
        <span>{label}</span>
    </NavLinkStyled>
);

const MobileMenu = ({ isPublisher, countsAllowed }) => {
    const { t } = useTranslation();
    return (
        <MobileMenuStyled>
            <NavLinkC label={t('Rozpis')} icon={'calendar'} to="/schedule" />
            <NavLinkC
                label={t('Schôdzky')}
                icon={'bag'}
                to="/serviceMeetings"
            />
            {(!isPublisher || !countsAllowed) && (
                <NavLinkC
                    label={t('Prednášky')}
                    icon={'speaker'}
                    to="/talks"
                    className={'counts'}
                />
            )}
            <NavLinkC
                label={t('Oznamy')}
                icon={'announcement'}
                to="/announcements"
            />

            {!!isPublisher && (
                <NavLinkC
                    label={t('Správa')}
                    icon={'envelope'}
                    to="/myAccount/myReport"
                    className={'report'}
                />
            )}

            {!!countsAllowed && (
                <NavLinkC
                    label={t('Počty')}
                    icon={'calculator'}
                    to="/counts"
                    className={'counts'}
                />
            )}
        </MobileMenuStyled>
    );
};

export default MobileMenu;

const miniMenu = keyframes` 50% {
    transform: scale(1.4);
  }`;

const NavLinkStyled = styled(NavLink)`
    padding: 0 2rem;
    width: 2.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${(props) => `${props.theme.colors.label}99`};
    font-size: 2rem;

    &.active,
    &:hover {
        background: #7b7b7b1c;
        color: ${(props) => props.theme.colors.label};
    }
    &.active i {
        animation: ${miniMenu} 0.2s linear;
        transform: scale(1.2);
    }
    & span {
        margin-top: 3px;
        font-size: 0.9rem;
    }
`;
const MobileMenuStyled = styled.nav`
    display: none;

    @media ${mediaQueries.s} {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0.5rem;
    }

    @media ${mediaQueries.menuM} {
        & a.report {
            display: none;
        }
    }
    @media ${mediaQueries.menuS} {
        & a.counts {
            display: none;
        }
    }
`;
